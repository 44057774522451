<template>
  <div style="padding:20px">
    <p>个人中心</p>
    <div>
      <img src="@/assets/icons/icon_2.png" width="200" style="float:left" alt="">
      <div style="height:200px;float:left;padding-left:50px;padding-top: 55px;">
        <div style="display: flex;">
          <div style="padding-right:15px">
            <p>姓名:</p>
            <p>手机:</p>
          </div>
          <div>
            <p>{{userName}}</p>
            <p>{{mobile}}</p>
          </div>
        </div>
        <div style="padding-top:15px">
          <el-button type="primary" @click="alterNameState = true" size="mini">修改姓名</el-button>
          <el-button type="success" @click="alterPwdState = true" size="mini">修改密码</el-button>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="alterNameState"
      title="修改用户名称"
      width="30%"
    >
      <div style="width:80%;margin:auto;padding:25px 0">
        <el-input v-model="userNameCopy" placeholder="请输入新名称"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button style="margin: auto;display: block;" type="primary" @click="changeName()"
            >确定</el-button
          >
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="alterPwdState"
      title="重置密码"
      width="30%"
    >
      <p>手机号：{{mobile}}</p>
      <div style="width:80%;margin:auto;padding:25px 0">
        <div style="display:flex;">
          <div style="width:60%">
            <el-input v-model="msgCode" placeholder="请输入验证码"></el-input>          
          </div>
          <el-button :disabled="phoneMsgNumber !== 60" style="margin:auto;display:block;margin-right:0;" type="success" @click="infoMsg()"
            ><span v-if="phoneMsgNumber !== 60">({{phoneMsgNumber}})</span>发送验证码</el-button
          >
        </div>
        <br>
        <el-input v-model="newPwd" placeholder="请输入新密码"></el-input>
        <br>
        <br>
        <el-input v-model="newPwdTwo" placeholder="请确认新密码"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button style="margin: auto;display: block;" type="primary" @click="saveNewPwd()"
            >确定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {ref} from'vue'
import {postData} from '@a'
import { ElMessage } from 'element-plus'
import md5 from 'js-md5'
export default {
setup(){
  let alterNameState = ref(false)
  let alterPwdState = ref(false)
  let msgCode = ref()         
  let newPwd = ref()
  let newPwdTwo = ref()
  let mobile = ref(window.localStorage.getItem('mobile'))
  let userName = ref(window.localStorage.getItem('userName'))
  let userNameCopy = ref(window.localStorage.getItem('userName'))
  let phoneMsgNumber = ref(60)
  const changeName = async()=>{
    if(userNameCopy.value === userName.value){
      ElMessage.error('请先修改名称')
      return
    }
    if(userNameCopy.value === '' || userNameCopy.value === ' '){
      ElMessage.error('名称不能为空')
      return
    }
    alterNameState.value = false
    try {
      await postData('login/resetUserInfo.json',{userName:userNameCopy.value})
      userName.value = userNameCopy.value
      window.localStorage.setItem('userName',userName.value)
      ElMessage({
        message: '修改成功！',
        type: 'success',
      })
    } catch (err) {
      console.log(err)
    }
  }
  const infoMsg = async()=>{
    try {
      let res = await postData('login/reset_pwd_get_code.json',{mobile:window.localStorage.getItem('mobile')})
      console.log(res)
      let time = setInterval(()=>{
        phoneMsgNumber.value -= 1
        if(phoneMsgNumber.value === 0){
          clearInterval(time)
          phoneMsgNumber.value = 60
        }
      },1000)
    } catch (err) {
      console.log(err)
      // ElMessage.error('发送失败，')
    }
  }
  const saveNewPwd = async()=>{
    if(mobile.value === ''){
      ElMessage.error('请输入验证码！')
      return
    }
    if(newPwd.value === ''){
      ElMessage.error('请输入密码！')
      return
    }
    if(newPwdTwo.value === ''){
      ElMessage.error('请输入再次输入密码！')
      return
    }
    if(newPwdTwo.value !== newPwd.value){
      ElMessage.error('两次不一致！')
      return
    }
    alterPwdState = false
    try {
      let param  = {
        mobile:mobile.value ,
        pwd:md5(newPwd.value).toUpperCase(),
        code:msgCode.value
      }
      let res = await postData('login/reset_pwd.json',param)
      if(res.code === 200){
        ElMessage({
          message: '修改成功！',
          type: 'success',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  return{
    alterNameState,
    alterPwdState,
    mobile,
    userName,
    userNameCopy,
    msgCode,
    newPwd,
    newPwdTwo,
    infoMsg,
    phoneMsgNumber,
    changeName,
    saveNewPwd,
  }
}
}
</script>

<style>

</style>